<template>
  <div :class="b('active-detail')" v-if="objSearchClinicDetail.avatar">
    <div class="media-body">
      <v-list-item three-line>
        <v-list-item-avatar class="profile" color="grey" size="64" tile>
          <v-img
            :src="'https://old.medveddoc.com' + objSearchClinicDetail.avatar"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title font-weight-bold">
            {{ objSearchClinicDetail.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="objSearchClinicDetail.legal">
            {{ objSearchClinicDetail.legal }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>

    <v-divider
      v-if="
        objSearchClinicDetail.city ||
        objSearchClinicDetail.address[2] ||
        objSearchClinicDetail.cityPart
      "
      class="ma-3"
    />

    <v-list>
      <v-subheader
        v-if="
          objSearchClinicDetail.city ||
          objSearchClinicDetail.address[2] ||
          objSearchClinicDetail.cityPart
        "
        >Адрес:
      </v-subheader>

      <v-list-item
        v-if="
          objSearchClinicDetail.city ||
          objSearchClinicDetail.address[2] ||
          objSearchClinicDetail.cityPart
        "
        link
      >
        <v-list-item-icon>
          <v-icon>
            {{ icons.mdiMapMarker }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            >{{ objSearchClinicDetail.city }},
            {{ objSearchClinicDetail.address[2] }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="objSearchClinicDetail.cityPart"
            >{{ objSearchClinicDetail.cityPart }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="objSearchClinicDetail.site" class="ma-3" />

      <v-subheader v-if="objSearchClinicDetail.site">Сайт:</v-subheader>
      <v-list-item
        v-if="objSearchClinicDetail.site"
        :href="objSearchClinicDetail.site"
        target="_blank"
      >
        <v-list-item-icon>
          <v-icon>
            {{ icons.mdiWeb }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            >{{ objSearchClinicDetail.site }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="ma-3" v-if="objSearchClinicDetail.calendar" />

      <v-subheader v-if="objSearchClinicDetail.calendar"
        >Расписание:
      </v-subheader>

      <v-list-item v-for="day in objSearchClinicDetail.calendar" :key="day.day">
        <div v-if="!day.isDayOff">
          {{ worksDays(day.day) }} : {{ day.start }}-{{ day.end }}
        </div>

        <div v-else>{{ day.day }} : {{ day.start }}</div>
      </v-list-item>

      <v-divider
        class="ma-3"
        v-if="
          objSearchClinicDetail.vk ||
          objSearchClinicDetail.facebook ||
          objSearchClinicDetail.instagram
        "
      />

      <v-subheader
        v-if="
          objSearchClinicDetail.vk ||
          objSearchClinicDetail.facebook ||
          objSearchClinicDetail.instagram
        "
        >Социальные сети:
      </v-subheader>

      <v-list-item>
        <v-btn
          v-if="objSearchClinicDetail.vk"
          :href="objSearchClinicDetail.vk"
          target="_blank"
          large
          icon
        >
          <v-icon x-large>
            {{ icons.mdiVk }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="objSearchClinicDetail.facebook"
          :href="objSearchClinicDetail.facebook"
          target="_blank"
          large
          icon
        >
          <v-icon x-large>
            {{ icons.mdiFacebook }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="objSearchClinicDetail.instagram"
          :href="objSearchClinicDetail.instagram"
          target="_blank"
          large
          icon
        >
          <v-icon x-large>
            {{ icons.mdiInstagram }}
          </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import * as names from "store/names";
import { mapGetters, mapActions } from "vuex";

import {
  mdiFacebook,
  mdiInstagram,
  mdiVk,
  mdiWeb,
  mdiMapMarker,
} from "@mdi/js";

const COMPONENT_NAME = "main-result-clinic-detail";
export default {
  name: COMPONENT_NAME,
  data: () => ({
    isActive: false,
    icons: {
      mdiFacebook,
      mdiInstagram,
      mdiVk,
      mdiWeb,
      mdiMapMarker,
    },
  }),

  created() {
    this.mainSearchClinicDetail({ departmentId: this.departmentId });
  },
  computed: {
    ...mapGetters({
      objSearchClinicDetail: names.OBJ_SEARCH_CLINIC_DETAIL,
      departmentId: names.DEPARTMENT_ID,
    }),
    worksDays() {
      return function (daysArr) {
        let fullWorksDays = daysArr.split(",");
        let worksDays = "";
        if (fullWorksDays.length > 1) {
          worksDays =
            fullWorksDays[0] + " - " + fullWorksDays[fullWorksDays.length - 1];
        } else {
          worksDays = fullWorksDays[0];
        }
        return worksDays;
      };
    },
  },
  methods: {
    ...mapActions({
      mainSearchClinicDetail: names.ACTION_MAIN_SEARCH_CLINIC_DETAIL,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

.display-m-none {
  @include phone-only {
    display: none;
  }
}

.media-body {
  overflow: visible;
}

.vacation-warning {
  width: 345px;
  padding: 15px;
  color: red;
  border: 1px solid red;
  border-radius: 5px;
  margin: 20px 0 0 0;
  @include phone-only {
    width: 280px;
    font-size: 0.7rem;
  }

  span {
    color: red;
    @include phone-only {
      font-size: 0.7rem;
    }
  }
}

.card-header button,
.card-header button:hover,
.card-header button:focus {
  outline: none;
}

.main-result-user-detail {
  &__content {
    padding: 0 30px;
    margin-left: 70px;
    margin-top: 10px;
    width: calc(100% - 70px);
    height: 88vh;
    position: absolute;
    top: 0;
    bottom: 15vh;
    left: 0;
    transform: translateX(100%);
    transition: all 0.15s ease-out;
    @include tablet-down {
      width: 100%;
    }

    &_active {
      overflow-x: hidden;
      overflow-y: auto;
      transform: translateX(0);
      transition: all 0.25s ease-out;
    }

    &_activeNav {
      transform: translateX(50%);
      transition: all 0.35s ease-out;
    }

    .btn-primary {
      border-color: $deep-blue;
      color: $deep-blue;
      background-color: white;

      &:hover,
      &:active,
      &:focus {
        background-color: $deep-blue;
        color: white;
      }
    }
  }

  &__back-btn {
    margin-top: 20px;
    margin-bottom: 40px;

    i {
      color: $deep-blue;
    }

    span {
      font-size: 18px;
      position: relative;
      margin-left: 10px;
      color: $deep-blue;

      &:hover {
        transition: all 0.35s ease-out;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__mb-5 {
    margin-bottom: 5px;
    display: inline-block;
  }

  &__active-detail {
    .media {
      &:hover {
        box-shadow: 0 2px 6px 5px #e3ecfc;
      }

      .media-heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
      }
    }

    .media-body .row {
      display: flex;
      flex-flow: row wrap;

      &:last-child button {
        width: 120px;
        height: 35px;
        background: initial;
      }

      @include tablet-down {
        font-size: 0.6rem;
      }
    }
  }

  .city-part {
    span {
      margin-right: 10px;
      color: $grey70;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .clinic-name {
    font-size: 14px;
    line-height: 18px;
    color: #3446a8;
  }
}

.bg-success {
  padding: 15px;
}

span {
  color: #000;
}

h2 {
  margin-bottom: 0;
}

.h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #262626;
}

.h4 {
  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3446a8;
  }
}

.underline {
  text-decoration: underline;
}

@include phone-only {
  p {
    padding-left: 0;
  }
  label {
    font-size: 15px;
  }

  .main-result-user-detail__content_active {
    padding: 15px 15px 30px;
    margin: 50px 10px 10px 40px;
    @include tablet-down {
      margin: 50px 10px 10px 1px;
    }
  }

  .main-result-user-detail__active-detail {
    .media {
      margin-top: 0;
      border-radius: 4px;
      border: 1px solid #f2f2f2;
      padding: 10px;

      span {
        font-size: 15px;
        @include phone-only {
          font-size: 15px;
        }
      }

      .media-heading {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
      }
    }

    .media-body .row {
      display: flex;
      flex-flow: row wrap;

      div {
        margin: auto;
      }
    }
  }
}
</style>
